import React from 'react'
import CheckIcon from '../../styles/icons/patch-check'

export default function Hero() {
    return (
        <section>
            <div className="container mx-auto pt-10 grid lg:grid-cols-hero md:grid-cols-hero xl:grid-cols-hero relative gap-4">
                <div className="self-center space-y-4">
                    <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14">
                        Get powerful insights<br />
                        for more productive teams
                    </h1>
                    <p className="text-body-light">Always know how employees use their time in working hours, track<br /> app and website usage, and increase overall productivity.</p>
                    <input role="button" value="Start your free trial now" type="submit" className="max-w-min font-semibold focus:outline-none text-white bg-primary text-xs h-12 px-7 rounded-md bg-blue-500 hover:bg-blue-600 hover:shadow-lg" />
                    <ul className="flex lg:space-x-2 md:space-x-2  flex-wrap">
                        <li className="flex space-x-2 items-center">
                            <CheckIcon />
                            <span className="text-xs text-body-light">No credit card required</span>
                        </li>
                    </ul>
                </div>
                <img className="lg:justify-self-end md:justify-self-end" src="/hero-dashboard.svg"></img>
            </div>
        </section>
    )
}
